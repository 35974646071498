
const Weather = ({data}) => {
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div className="weather widget__inner">
      <div className="weather__icon">☀️</div>
      <div className="weather__details">
        <span className="temp">{data.temperature.value}</span> °
      </div>
      <div className="weather__location">Trondheim</div>
    </div>
  );
};

export default Weather;