const Rings = ({a = 0, b = 0, c = 0}) => {
  return (
    <div className="stat widget__inner">
      <svg className="ActivityRings" viewBox='0 0 37 37'>
        <g className="ring ring1">
          <circle className="background" cx="50%" cy="50%" r="15.915" strokeWidth="3" />
          <circle className="completed" cx="50%" cy="50%" r="15.915" strokeWidth="3" strokeDasharray={`${a}, 100`} />
        </g>
        <g className="ring ring2">
          <circle className="background" cx="50%" cy="50%" r="15.915" strokeWidth="4" />
          <circle className="completed" cx="50%" cy="50%" r="15.915" strokeWidth="4" strokeDasharray={`${b}, 100`} />
        </g>
        <g className="ring ring3">
          <circle className="background" cx="50%" cy="50%" r="15.915" strokeWidth="6" />
          <circle className="completed" cx="50%" cy="50%" r="15.915" strokeWidth="6" strokeDasharray={`${c}, 100`} />
        </g>
      </svg>
    </div>
  );
};

export default Rings;