import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend } from 'recharts';

const data = [
  {
    name: 'Mon',
    pr: 2,
    pu: 4,
  },
  {
    name: 'Tue',
    pr: 1,
    pu: 9,
  },
  {
    name: 'Wed',
    pr: 0,
    pu: 16,
  },
  {
    name: 'Thu',
    pr: 4,
    pu: 6,
  },
  {
    name: 'Fri',
    pr: 0,
    pu: 0,
  },
];


const BarChartElement = ({items = []}) => {
  items = Object.values(items);

  return (
    <div className="widget__inner">
      <span>Aktivitet</span>
      <ResponsiveContainer>
        <BarChart width={400} height={400} data={items} margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
          <XAxis dataKey="label" />
          <YAxis />
          <Legend />
          <Bar dataKey="repo_push" fill="#2980b9" />
          <Bar dataKey="repo_pr:open" fill="#27ae60" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartElement;