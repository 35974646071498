import classnames from 'classnames';
import {isDayOfMonthToday} from '../helpers';

const Day = ({date, events = [], current = false, isPadding = false, updateDay = false}) => {
  const dayClasses = classnames({
    day: true,
    'day--padding': isPadding,
    'day--current': current,
    'day--updates': updateDay,
  });

  return (
    <div className={dayClasses}>
      <span className="day__date">{date}</span>
      {events.map(e => <span>{e.label}</span>)}
    </div>
  );
};

// @todo Should receive date as prop and update if date changes.
const Calendar = ({events = []}) => {
  const now = new Date();
  const today = now.getDate();
  const thisMonth = now.getMonth();
  now.setDate(1);
  const monthStarts = (now.getDay() === 0 ? 7 : now.getDay()); // Day of week the month starts.
  let padStart = monthStarts - 1;

  const output = [];
  
  let finished = false;
  let lastDayReached = false;
  let padEnd;

  while (!finished) {
    if (padStart > 0) {
      output.push(<Day key={`start-${padStart}`} isPadding date="" />);
      padStart -= 1;
      continue;
    }

    const d = now.getDate();
    const m = now.getMonth();
    if (d <= 31 && m === thisMonth) {
      const todayEvents = events.filter(i => i.date === `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${(now.getDate()).toString().padStart(2, '0')}`);

      const isToday = isDayOfMonthToday(now, 3, 4);

      output.push(<Day key={d} date={d} current={today === d} events={todayEvents} updateDay={isToday} />);
      now.setDate(d + 1);
      continue;
    }

    if (!lastDayReached) {
      const dayOfWeek = (now.getDay() === 0 ? 7 : now.getDay());
      padEnd = 7 - (dayOfWeek - 1);
      if (padEnd === 7) {
        padEnd = 0;
      }
      lastDayReached = true;
      continue;
    }
  
    if (padEnd > 0) {
      output.push(<Day key={`end-${padEnd}`} isPadding date="" />);
      padEnd -= 1;
      continue;
    }


    finished = true;
  }

  return (
    <div className="calendar">
      {output}
    </div>
  );
};

export default Calendar;

/*
7 - 1 = 6
7 - 2 = 5
7 - 3 = 4
7 - 4 = 3
7 - 5 = 2
7 - 6 = 1
7 - 7 = 0
*/