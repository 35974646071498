import React, { useState, useEffect } from 'react';
import './app.scss';

import useWebSocket from 'react-use-websocket';
import GridLayout from 'react-grid-layout';
import produce from "immer";
import SemVer from 'semver';

import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

import {filterList, ensureList} from './helpers';

import Clock from './components/Clock';
import Status from './components/Status';
import Table from './components/Table';
import Stat from './components/Stat';
import Overlay from './components/Overlay';
import Video from './components/Video';
import Weather from './components/Weather';
import Calendar from './components/Calendar';
import PauseScreen from './components/PauseScreen';
import Notification from './components/Notification';
import Rings from './components/Rings';
import Table2 from './components/SslTable';
import BarChartElement from './components/BarChart';
import Progress from './components/Progress';

import ActionsPanel from './components/ActionsPanel';

function App() {
  const [socketUrl, setSocketUrl] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [videoInfo, setVideoinfo] = useState(false);
  const [pauseScreen, setPauseScreen] = useState(false);
  const [pauseMessage, setPauseMessage] = useState({message: '', type: 'info'});
  const [notificationData, setNotificationData] = useState({message: '', type: '', duration: 10});
  const [wsActivity, setWsActivity] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [lastCleanup, setCleanupTime] = useState(Math.floor(Date.now() / 1000));

  // 'ws://localhost:3001/websocket'

  // const removeActionItem = (id) => {
  //   setWorkflowData(produce(draft => {
  //     delete draft[id];
  //   }));
  // };

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    onError: (error) => {
      console.log('Got error');
      console.log(error);
      console.log(error.message);
    },
    onOpen: () => {
      setWebsocketConnected(true);
      sendJsonMessage({
        payload_type: 'register',
        clientType: 'dashboard',
      });
    },
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    onMessage: (message) => {
      const body = JSON.parse(message.data);

      const {data, type, extra} = body;
      setWsActivity(true);
      
      if (type === 'data') {
        setDashboardData(produce(draft => {
          for (const [key, value] of Object.entries(data)) {
            draft[key] = value;
          }
        }));
      }
      else if (type === 'command') {
        if (data.command === 'video') {
          setVideoinfo({
            id: data.id,
          });
        }
        else if (data.command === 'refresh') {
          window.location.reload();
        }
        else if (data.command === 'pause') {
          setPauseScreen(!pauseScreen);
        }
      }
      else if (type === 'notification') {
        setNotificationData({
          message: data.message,
          type: data.notification_type,
          duration: data.duration || 10,
          sound: data.sound || false,
          size: data.size || 'normal',
        });
      }
      else if (type === 'workflow') {
        setWorkflowData(produce(draft => {
          draft[data.id] = data;
        }));
      }

      if (extra) {
        setDashboardData(produce(draft => {
          const i = draft[extra.ref].findIndex(i => i.id === extra.id);
          if (i !== -1) {
            draft[extra.ref][i][extra.field] = extra.value;
          }
        }));
      }

      const cleanupRunTime = Math.floor(Date.now() / 1000);
      if ((cleanupRunTime - lastCleanup) > 1800 || lastCleanup === 0) {
        // Remove workflows that are old.
        Object.values(workflowData).forEach(item => {
          const finished = new Date(item.finished);
          const now = new Date();
          const diff = (now - finished) / 1000;
          if (diff < 60) {
            setWorkflowData(produce(draft => {
              delete draft[item.id];
            }));
          }
        });

        setCleanupTime(cleanupRunTime);
      }
    },
  }, socketUrl !== false);

  useEffect(() => {
    // Authenticate.
    (async () => {
      let params = new URLSearchParams(document.location.search.substring(1));
      let key = params.get('key');

      if (!key || key === '') {
        setPauseMessage({
          message: 'Missing key parameter',
          type: 'error',
        });
        setPauseScreen(true);
        return;
      }
      
      setSocketUrl(`${process.env.REACT_APP_WS_ENDPOINT}?token=${key}`);
      // @TODO Set some error message if token is provided but wrong.
    })();
  }, []);

  useEffect(() => {
    // if a notification is set, start a timer.
    if (notificationData.message !== '') {
      setTimeout(() => {
        setNotificationData({
          message: '',
          type: 'status',
          duration: 10,
        });
      }, notificationData.duration * 1000);
    }
  }, [notificationData]);

  useEffect(() => {
    if (wsActivity) {
      setTimeout(() => {
        setWsActivity(false);
      }, 2000);
    }
  }, [wsActivity]);

  // @TODO Make sure it is stored somewhere reasonable.
  const layout = [
    {i: 'sites', x: 0, y: 0, w: 4, h: 20},
    {i: 'clock', x: 10, y: 0, w: 2, h: 3},
    {i: 'weather', x: 8, y: 0, w: 2, h: 3},
    {i: 'security', x: 3, y: 0, w: 3, h: 4},
    {i: 'calendar', x: 8, y: 2, w: 4, h: 6},
    {i: 'player', x: 6, y: 8, w: 4, h: 1},
    {i: 'total_sites', x: 4, y: 0, w: 2, h: 3},
    {i: 'rings', x: 6, y: 0, w: 2, h: 3},
    {i: 'ssl', x: 8, y: 16, w: 4, h: 6},
    {i: 'activity', x: 4, y: 2, w: 4, h: 6},
    {i: 'd10', x: 4, y: 6, w: 4, h: 4}
  ];

  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;
  const rowHeight = ((viewPortHeight - 30 - 200 - 40) / 20);
  // Høyde på skjerm - statusbar - (10px mellom radene) - (40px padding over under) / antall rader vi vil ha.

  let bodyStyles = {};

  const layoutChange = (l) => {
    console.log(l);
  };

  return (
    <div className="app" style={bodyStyles}>
      <GridLayout className="layout" layout={layout} cols={12} rowHeight={rowHeight} width={viewPortWidth - 40} isBounded onLayoutChange={layoutChange}>
        <div key="sites" className="sitelist widget" >
          <Table items={dashboardData?.site_data || []} />
        </div>

        <div key="clock" className="widget">
          <Clock />
        </div>
        <div key="weather" className="widget">
          <Weather data={dashboardData.yr} />
        </div>

        <div key="calendar" className="widget w-calendar">
          <Calendar events={dashboardData?.events || []} />
        </div>

        <div key="total_sites" className="widget">
          <Stat label="Sites" value={dashboardData?.site_data?.length || 0} />
        </div>

        <div key="rings" className="widget">
          <Rings a={10} b={45} c={70} />
        </div>

        <div key="ssl" className="widget sitelist overflow">
          <Table2 items={dashboardData?.site_data || []} ssl_status={dashboardData?.ssl_status || {}} />
        </div>

        <div key="activity" className="widget">
          <BarChartElement items={dashboardData?.metrics_weekly || []} />
        </div>
        Progress
        <div key="d10" className="widget">
          <Progress current={filterList(dashboardData?.site_data, (i => SemVer.gte(i.version, '10.0.0'))).length} total={ensureList(dashboardData?.site_data).length} label="Drupal 10" />
          <Progress current={filterList(dashboardData?.site_data, (i => SemVer.gte(i.php_version + '.0', '8.1.0'))).length} total={ensureList(dashboardData?.site_data).length} label="PHP 8" color="#2980b9" />
        </div>

      </GridLayout>

      <Status ws={websocketConnected} activity={wsActivity} />

      {videoInfo ? <Overlay><Video embedId={videoInfo.id} onEnd={() => setVideoinfo(false)} /></Overlay> : null}

      {pauseScreen ? <PauseScreen {...pauseMessage} /> : null}

      {notificationData.message !== '' ? <Notification {...notificationData} /> : null}
      <ActionsPanel data={workflowData} />
    </div>
  );
}

export default App;
