
const Status = ({ws = false, activity  = false}) => {
  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;

  return (
    <div className="status">
      <span>{viewPortWidth}px / {viewPortHeight}px</span>
      <span>Socket: {ws ? 'Tilkoblet' : 'Ikke tilkoblet'}</span>
      {activity ? <span>Henter data</span> : null}
    </div>
  );
};

export default Status;