import { useEffect, useState } from 'react';
import useInterval from '../hooks/useInterval';
import { getWeekNumber } from '../helpers';

const months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

const getFormattedTime = () => {
  const now = new Date();
  const hour = now.getHours().toString().padStart(2, '0');
  const minute = now.getMinutes().toString().padStart(2, '0');

  const day = now.getDate().toString().padStart(2, '0');
  const month = months[now.getMonth()];
  const year = now.getFullYear();

  return {
    hour,
    minute,
    date: `${day}. ${month} ${year}`,
  };
};

const Clock = () => {
  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [date, setDate] = useState('');

  useEffect(() => {
    const t = getFormattedTime();
    setHour(t.hour);
    setMinute(t.minute);
    setDate(t.date);
  }, []);

  useInterval(() => {
    const t = getFormattedTime();
    setHour(t.hour);
    setMinute(t.minute);
    setDate(t.date);
    // TODO Don't update if time hasn't changed.
  }, 5000);

  return (
    <div className="clock widget__inner">
      <div className="time">
        <span>{hour}</span>:<span>{minute}</span>
      </div>
      <div className="date">{date}</div>
      <div className="week">Uke {getWeekNumber()}</div>
    </div>
  );
};

export default Clock;