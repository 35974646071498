
const PauseScreen = ({message = '', type = 'info'}) => {
  return (
    <div className="pause-screen">
      <svg xmlns="http://www.w3.org/2000/svg" id="bouvet-logo" viewBox="0 0 167 41">
        <title>Bouvet logo</title>
        <g fill="#fff">
          <path d="M113.475 13.4391L105.401 33.8829L97.2667 13.4391H90.9193L102.226 39.8617H107.996L119.306 13.4391H113.475ZM81.5151 13.4391V27.3884C81.5151 32.3993 79.0361 35.3059 74.7082 35.3059C70.3803 35.3059 67.9013 32.4008 67.9013 27.3884V13.4391H62.2471V27.6175C62.2471 35.4789 67.0345 40.429 74.7097 40.429C82.385 40.429 87.1724 35.4774 87.1724 27.6175V13.4391H81.5151ZM44.6497 12.8125C42.535 12.808 40.4463 13.2798 38.5421 14.1915C33.8377 16.4611 30.873 21.1958 30.9207 26.3644C30.9207 33.1441 37.3249 41.001 42.8055 41.001C44.1917 40.9903 45.475 40.2804 46.2097 39.1198C48.4612 35.7595 50.7696 33.8268 54.1692 32.3446C57.3444 30.9778 58.2082 29.6124 58.2082 26.1399C58.2066 18.6198 52.2666 12.8125 44.6497 12.8125ZM50.6498 30.5227C48.3413 31.9457 47.1318 33.1426 46.2081 35.0192C45.8254 35.7702 45.0401 36.2359 44.1887 36.2162C40.4955 36.2162 36.2859 31.4299 36.2859 27.2185C36.2859 22.4929 39.8039 18.9596 44.5913 18.9596C49.1328 18.9763 52.8014 22.6249 52.7845 27.1078C52.7845 27.1442 52.7829 27.1806 52.7829 27.2185C52.7829 28.6976 52.2666 29.5548 50.6498 30.5227ZM14.0749 12.8125C10.9027 12.7792 7.84122 13.964 5.53742 16.1167L5.48054 0.000976562H0V39.8632H5.65576V37.1871C7.96725 39.2397 10.9657 40.3744 14.0749 40.3744C21.6917 40.3744 27.4611 34.3942 27.4611 26.5935C27.4611 18.7928 21.6917 12.8125 14.0749 12.8125ZM13.6138 35.2498C8.99697 35.2498 5.53588 31.5482 5.53588 26.5935C5.53588 21.6388 8.99697 17.9371 13.6138 17.9371C18.2306 17.9371 21.6916 21.6388 21.6916 26.5935C21.6916 31.5482 18.2306 35.2498 13.6138 35.2498ZM167 13.4391H158.808L158.747 0.000976562H153.266V13.4391H149.501V18.277H153.266V39.8617H158.864L158.807 18.277H166.998V13.4391H167Z"/>
          <path d="M147.181 28.378H126.5C126.918 30.9418 127.95 33.0733 130.296 34.3719C131.534 35.0288 132.921 35.3641 134.327 35.3489C136.766 35.3823 139.064 34.9014 141.029 33.3722C141.623 32.8655 142.19 32.3269 142.726 31.761L146.508 35.4035C145.752 36.3001 144.878 37.0935 143.908 37.7595C140.788 39.9122 137.298 40.7041 133.552 40.4978C130.515 40.3931 127.612 39.2401 125.35 37.2391C122.789 34.9666 121.435 32.0645 121.056 28.7011C120.661 25.8036 121.113 22.8559 122.356 20.2026C124.334 16.1232 127.658 13.8385 132.109 13.1619C134.857 12.6841 137.687 13.0694 140.201 14.2663C143.722 15.9821 145.766 18.8858 146.743 22.5935C147.231 24.4822 147.38 26.4392 147.181 28.378ZM126.613 23.9012H141.421C140.946 20.5409 138.126 17.9816 134.694 17.795C130.386 17.5523 127.346 19.8491 126.613 23.9012Z"/>
        </g>
      </svg>
      {message ? <span className={`message message--${type}`}>{message}</span> : null}
    </div>
  );
};

export default PauseScreen;