
const Progress = ({current, total, label, color = '#27ae60'}) => {
  const percentage = (current / total) * 100;

  return (
    <div className="progress">
      
      <div className="progress__label">
        <span>{label}</span>
        <span>{Math.ceil(percentage)}%</span>
      </div>
      
      <div className="progress__wrapper">
        <div className="progress__bar" style={{width: `${percentage}%`, backgroundColor: color}} />
      </div>
      <div className="progress__details">{current} of {total}</div>
    </div>
  );
};

export default Progress;