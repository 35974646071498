const Overlay = ({children, hide = false}) => {
  if (!children || hide) {
    return null;
  }

  return (
    <>
      <div className="overlay">
        {children}
      </div>
      <div className="backdrop" />
    </>
  );
};

export default Overlay;