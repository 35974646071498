import YouTube from 'react-youtube';

const Video = ({embedId, onEnd = () => {}}) => {
  const opts = {
    height: '480',
    width: '853',
    playerVars: {
      autoplay: 1,
      controls: 0,
      enablejsapi: 1,
    },
  };

  return (
    <div className="video-responsive">
      <YouTube
        videoId={embedId}
        opts={opts}
        onReady={() => console.log('Video ready')}
        onEnd={onEnd}
        onError={onEnd}
      />
    </div>
  );
};

export default Video;