import warning from '../sounds/warning.wav';

const Notification = ({message, sound, type = 'status'}) => {

  if (sound === 'warning') {
    const errorSound = new Audio(warning);
    errorSound.play();
  }

  return (
    <div className={`notification notification--${type}`}>
      <div className="notification__inner">{message}</div>
    </div>
  );
};

export default Notification;