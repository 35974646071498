import Indicator from './Indicator';
import {sortSites} from '../helpers';
import Classnames from 'classnames';

function Row({title, version, php_version, token, is_up, first, last, odd}) {
  const rowClasses = Classnames({
    'site-down': !is_up,
  });

  return (
    <tr className={rowClasses}>
      {/* <td><Indicator status={is_up} first={first} last={last} odd={odd} /></td> */}
      <td className={token !== '' ? 'connected' : ''}>{title}</td>
      <td>{version}</td>
      <td>{php_version}</td>
    </tr>
  );
}

function Table({items}) {
  items = sortSites(items);

  return (
    <table>
      <thead>
        <tr>
          <th>Navn</th><th>Versjon</th><th>PHP</th>
        </tr>
      </thead>
      <tbody>
        {items.map((i, index) => <Row key={i.id} {...i} first={index === 0} last={index === items.length - 1} odd={index % 2} />)}
      </tbody>
    </table>
  );
}

export default Table;