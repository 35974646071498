import { useState } from 'react';
import classNames from 'classnames';
import {ReactComponent as Cog} from '../assets/cog1.svg';
import {ReactComponent as Check} from '../assets/check.svg';
// import {ReactComponent as Close} from '../assets/close.svg';
import {ReactComponent as HourGlass} from '../assets/hourglass.svg';
import { elapsedTime } from '../helpers';
import useInterval from '../hooks/useInterval';

const icons = {
  'in-progress': Cog,
  completed: Check,
  // completed: Close, // Must find a better way for this.
  queued: HourGlass,
};

const Job = ({name, branch, started_by, started, finished, status}) => {
  const jobClasses = classNames({
    'panel__job': true,
    'job': true,
    'job--running': status === 'in-progress',
  });

  const Icon = icons[status];

  return (
    <div className={jobClasses}>
      <div className="job__indicator">
        <Icon className="job__icon" />
      </div>
      <div className="job__data">
        <span className="job__project">{name}</span>
        <span className="job__branch">{branch}</span>
        <span className="job__started-by">{started_by}</span>
        <span className="job__elapsed">{elapsedTime(started, finished)}</span>
      </div>
    </div>
  );
}

const ActionsPanel = ({data = {}}) => {
  const [t, setT] = useState(0);
  const workloads = Object.values(data);

  const items = workloads.filter((item) => {
    if (!item.finished) {
      return true;
    }
    const finished = new Date(item.finished);
    const now = new Date();
    const diff = (now - finished) / 1000;
    if (diff < 60) { // Items hidden after X seconds when completed
      return true;
    }
    return false;
  });

  const panelClasses = classNames({
    'panel': true,
    'panel--open': items.length > 0,
  });

  useInterval(() => {
    setT(t + 1);
  }, 1000);

  return (
    <div className={panelClasses}>
      <h1>Workflow</h1>

      {items.map(item => <Job key={item.name} {...item} />)}
    </div>
  );
};

export default ActionsPanel;