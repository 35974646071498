const semver = require('semver');

function semverRegex() {
	return /(?:(?<=^v?|\sv?)(?:(?:0|[1-9]\d{0,9})\.){2}(?:0|[1-9]\d{0,9})(?:-(?:0|[1-9]\d*?|[\da-z-]*?[a-z-][\da-z-]*?){0,100}(?:\.(?:0|[1-9]\d*?|[\da-z-]*?[a-z-][\da-z-]*?))*?){0,100}(?:\+[\da-z-]+?(?:\.[\da-z-]+?)*?){0,100}\b){1,200}/gi;
}

export function updateObjectInArrayById(array, el) {
  return array.map((item) => {
    if (item._id !== el._id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...el
    }
  })
}

/**
 * Sort site list by Drupal version, and hide sites below 8.
 * 
 * @param {*} sites 
 * @returns array
 */
export function sortSites(sites) {
  const copy = Array.from(sites);

  copy.sort((v1, v2) => {

    var sv1 = semverRegex().exec(v1.version || '8.0.1')[0] || v1.version;
		var sv2 = semverRegex().exec(v2.version || '8.0.1')[0] || v2.version;

		const versionCompared = semver.rcompare(sv1, sv2);
    if (versionCompared !== 0) {
      return versionCompared;
    }
    if (v1.title < v2.title) {
      return -1;
    }
    if (v1.title > v2.title) {
      return 1;
    }
    return 0;


  });

  return copy.filter(i => semver.gte(i.version || '8.0.1', '8.0.0'));
}

export function sortSitesOld(sites) {
  const copy = Array.from(sites);

  copy.sort((a, b) => {
    if (a.token !== '') return -1;
    if (a.token === '') return 1;
    
    return a.title > b.title ? 1 : -1;
  });

  return copy;
}

/**
 * Get the date object matching input params.
 * 
 * @param {Date} date 
 * @param {int} targetDay 
 * @param {int} targetOccurence 
 * @returns Date|bool
 */
export const isDayOfMonth = (date, targetDay, targetOccurence) => {
  const targetMonth = date.getMonth();
  let dateOfMonth = 1;
  let daysFound = 0;
  
  const copiedDate = new Date(date);
  copiedDate.setDate(dateOfMonth);
  let month = copiedDate.getMonth();

  while (month === targetMonth) {
    if (copiedDate.getDay() === targetDay) {
      daysFound += 1;
    }
    
    if (daysFound === targetOccurence) {
      return copiedDate;
    }
    
    dateOfMonth += 1;
    copiedDate.setDate(dateOfMonth);
  } 
  
  return false;
};

/**
 * Check if date matches.
 * 
 * @param {Date} date 
 * @param {int} targetDay 
 * @param {int} targetOccurence 
 * @returns bool
 */
export const isDayOfMonthToday = (date, targetDay, targetOccurence) => {
  const target = isDayOfMonth(date, targetDay, targetOccurence);

  if (!target) {
    return false;
  }
  
  if (target.getFullYear() === date.getFullYear() && target.getMonth() === date.getMonth() && target.getDate() === date.getDate()) {
    return true;
  }
  return false;
  
};

export const elapsedTime = (datestring, finished_datestring, extended = false) => {
  let now = new Date();
  if (finished_datestring) {
    now = new Date(finished_datestring);
  }
  const started = new Date(datestring);
  const diff = Math.abs(now - started) / 1000;

  let hours = diff / 3600;
  let mins = (diff % 3600) / 60;
  let secs = (mins * 60) % 60;

  hours = Math.trunc(hours);
  mins = Math.trunc(mins);
  secs = Math.trunc(secs);

  if (extended) {
    const formated_hour = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
    const formated_min = `${mins.toString().padStart(2, '0')}`;
    const formated_sec = hours > 0 ? '' : `:${secs.toString().padStart(2, '0')}`;

    return {
      diff: diff,
      formated: `${formated_hour}${formated_min}${formated_sec}`,
    };
  }

  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

export const getWeekNumber = function() {
  var date = new Date();
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
};

export const filterList = (list, cb) => {
  if (typeof list == 'undefined') {
    return [];
  }
  return list.filter(cb);
}

export const ensureList = (list) => {
  if (typeof list == 'undefined') {
    return [];
  }
  return list;
}