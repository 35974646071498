
const Stat = ({value, label, color = 'transparent'}) => {
  return (
    <div className="stat widget__inner" style={{borderLeftColor: color}}>
      <span className="stat__value">{value}</span>
      <span className="stat__label">{label}</span>
    </div>
  );
};

export default Stat;