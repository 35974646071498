function Row({title, expires, expires_pretty}) {
  return (
    <tr>
      <td>{title}</td>
      <td>{expires_pretty}</td>
    </tr>
  );
}

function Table({items, ssl_status}) {
  const formatExpire = (ts) => {
    const date = new Date(ts * 1000);
    return [
      date.getFullYear(),
      date.getMonth()+1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
  };
  const now = Math.floor(Date.now() / 1000);


  const data = items.map(i => {
    const expires = ssl_status[i.id] ?? 0;
    const pretty = expires !== 0 ? formatExpire(expires) : 'Ukjent';

    return {
      id: i.id,
      title: i.title,
      expires: expires,
      expires_pretty: `${pretty[2]}.${pretty[1]}.${pretty[0]}`,
    };
  }).sort((a, b) => a.expires - b.expires).filter(i => i.expires - now < (60 * 60 * 24 * 20));

  if (data.length == 0) {
    return <p>Ingen sertifikater utløper de nærmeste 20 dager.</p>
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Navn</th><th>Utløper</th>
        </tr>
      </thead>
      <tbody>
        {data.map((i, index) => <Row key={i.id} {...i} first={index === 0} last={index === items.length - 1} odd={index % 2} />)}
      </tbody>
    </table>
  );
}

export default Table;